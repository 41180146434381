.emphasized-text {
  font-style: italic;
}
.underlined-text {
  text-decoration: underline;
}
.small-caps-text {
  font-variant: small-caps;
}
.equation {
  align-items: center;
}